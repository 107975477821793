<template>
  <div class="FAQHolder">
       <Slider />
    <v-flex xs10 sm10 offset-sm1 class="">

      <p><br></p>
        <p class="terms-header">Definitions</p>
        <p>The <b>ZamuPay Payment Gateway</b> means an application for making payment transactions for the purpose of an Issue of Electronic Money or an Electronic Money Payment that integrates individual payment methods and facilitates transfer of money through the ZamuPay Payment System.</p>
        <p>The <b>Terms and Conditions</b> mean these terms for using the ZamuPay Payment Gateway.</p>
        <p>The Terms and Conditions are issued by XXXXX Limited trading as ZamuPay.</p>
        <p>XXXXX Limited (hereinafter the &ldquo;Provider&rdquo;) is a private limited company incorporated in the Republic of Kenya.</p>
        <p><b>&ldquo;The Merchant&rdquo;</b> means an individual or a legal entity that uses ZamuPay Payment Gateway services.</p>
        <p class="terms-header" >SCOPE OF TERMS</p>
        <p>These Terms and Conditions lay down rules that govern the use of functionalities of the ZamuPay Payment Gateway by Merchants who use it for the purpose of processing payments across various channels.</p>
        <p class="terms-header">OPENING OF THE ZAMUPAY PAYMENT GATEWAY</p>
        <p>The Merchant shall be entitled to use the ZamuPay Payment Gateway at the moment when the Merchant confirms that it accepts these Terms and Conditions, and when the Provider authorizes the Merchant to use the ZamuPay Payment Gateway.</p>
        <p>The Merchant acknowledges and accepts that the availability of the ZamuPay Payment Gateway is subject to activation as part of which the Provider assess whether the Merchant and the activity that the Merchant operates meets the requirements pursuant to the Payment Gateway Terms and Conditions.</p>
        <p>The Provider reserves the right not to establish cooperation in those cases when the Provider believes that, the Payment Gateway Terms and Conditions may be breached.</p>
        <p>The Merchant acknowledges that if its activity is evaluated as high-risk, the Provider is entitled to apply fees on an individual basis or other security parameters.</p>
        <p>Before the Merchant commences productive operation, the Merchant is obliged to duly test the integration of its application that is used for offering and selling goods or services with the ZamuPay Payment Gateway in accordance with the Merchant&rsquo;s directions, instructions and recommendations.</p>
        <p class="terms-header">USE OF THE PAYMENT GATEWAY</p>
        <p>When using the ZamuPay Payment Gateway, the Merchant undertakes:</p>
        <ul>
            <li>To offer and enable its contracting partners to make payments via the ZamuPay Payment Gateway;</li>
            <li>Not to misuse the Provider&rsquo;s copyright and other rights to intangible assets and refrain from any infringements of such rights that the Provider has;</li>
            <li>To remove the ZamuPay Payment System software from its system and stop using the ZamuPay brand immediately upon terminating its contractual relationship with the Provider and to remove any other logos, trademarks and names used in relation to the contractual relationship (in particular also the logos, trademarks and names of our contracting partners that the Merchant used with the Provider&apos;s consent and with the consent of the Provider&rsquo;s contracting partners);</li>
            <li>To provide all necessary cooperation to the Provider or to its contracting partner and if need be also to state authorities and institutions and thus enable the Provider to duly and timely fulfil its obligations that follow from pertinent legislation or contractual provisions;</li>
            <li>To provide its application integrated with the ZamuPay Payment Gateway with all elements and information required by pertinent legislation, in particular the Consumer Protection Act, the Data Protection Act as well as requirements of banks and card associations common in this segment;</li>
            <li>To inform the Provider without delay and update on an ongoing basis its identification data, website addresses of applications integrated with the ZamuPay Payment Gateway, bank account number designated for the payment of transactions that the Provider has received and that are to be credited to the Merchant;</li>
            <li>To visibly place logos that designate accepted payment methods in accordance with a template provided by the Provider and clearly state that data transmission is secured by compliance to relevant international protocols;</li>
            <li>Not to discriminate or not to give preference to any payment method whose use is offered by the ZamuPay Payment System;</li>
            <li>To offer all payment methods absolutely equally and not to set any financial or any other limits or limitations for the use of a specific payment method;</li>
            <li>Not to transfer fees related to the use of a certain payment method to its customers;</li>
            <li>To keep records of the delivery of goods or provision of a service to a contracting partner for at least 7 years from when the transaction was made and to provide such records upon request without delay to us or to our partners. The above records have to contain sufficient information to prove that a given service or goods have been ordered by a customer of the Merchant and that the service or goods have been provided to the payment card holder in an agreed manner;</li>
            <li>To return the amounts of payment transactions via the ZamuPay Payment Gateway when its customer withdraws from a contract unless agreed otherwise. When its customer withdraws from a contract the Merchant is not entitled to return the amounts of payment transactions in cash.</li>
        </ul>
        <p>When using ZamuPay Payment Gateway the Merchant undertakes to refrain from the following activities in relation to making payment transactions:</p>
        <ul>
            <li>Allowing the payment of a single order by multiple payments;</li>
            <li>Making or accepting a payment for a transaction that does not stem directly from a contractual relationship between a Merchant and its client</li>
            <li>Using or accepting a payment in pursuant to an illegal or criminal activity;&nbsp;</li>
            <li>Making a payment transaction that has already been settled again regardless of a potential consent granted by a client;</li>
            <li>Levying any tax on a payment transaction with the exception of cases when it is expressly required by respective legal regulations.</li>
        </ul>
        <p>The Merchant shall not use the ZamuPay Payment Gateway for other purposes and activities than those the Merchant stated when the Merchant has activated the ZamuPay Payment Gateway.</p>
        <p>By accepting the Payment Gateway Terms, the Merchant grants the Provider its consent to use the Merchant&rsquo;s trade name, designation, company and/or logo used the in its trading activity for promotional and marketing purposes.</p>
        <p>Upon ZamuPay&rsquo;s prior consent and under terms set by the Provider, the Merchant is entitled to use the logos and trademarks of card associations in the Merchant&rsquo;s own advertising and promotional materials.</p>
        <p>Where ZamuPay is unable to complete a transaction, the Merchant will be notified by through various communication channels as to the reasons for not completing the transaction.</p>
        <p>Zamu Pay will not be compelled to refund or compensate the sender if monies are sent to a recipient mistakenly or in error or fraudulently or under duress and subsequently redeemed for cash or otherwise spent by the recipient of the mistaken, erroneous or fraudulent transaction and the Merchant hereby agree to hold ZamuPay harmless for any losses arising from a mistaken, erroneous or fraudulent transfer of funds or any transfer of funds effected under duress or under coercion or criminal force.</p>
        
        <p class="terms-header">FEES FOR USE OF THE PLAFTORM</p>
        <p>Transaction limits and charges as prescribed by ZamuPay shall apply each time the Merchant uses any of the functionalities.</p>
        <p>The Merchant will be charged a fee for any transactions it undertake. The fee will be in accordance with the published tariffs.</p>
        <p class="terms-header">LIMITATION OF SERVICE AVAILABILITY</p>
        <p>ZamuPay shall provide maintenance and technical support for the System as specified by ZamuPay from time to time. ZamuPay&rsquo;s goal is to ensure that the System and the Services are available on ZamuPay&rsquo;s infrastructure all the time. However, ZamuPay is not liable for any damages to the Merchant or the Customer caused by i) force majeure reasons (including but not&nbsp;&nbsp;limited to Internet traffic disruptions) or ii) disruptions in ZamuPay&rsquo;s suppliers services (including but not limited to disruptions in online payment services of banks). Furthermore, ZamuPay shall not be liable for any damages to the Merchant or the Customer caused by any downtime of the System and/or the Services during any maintenance, support or technical assistance activities or when suspected illegal activities are investigated and solved.</p>
        <p>ZamuPay may, in its sole and absolute discretion without liability to the Merchant or any third party, terminate use of the Service for any reason, including without limitation inactivity or violation of this Terms of Service or other policies ZamuPay may establish from time to time.</p>
        <p>Upon termination of use of the Platform, the Merchant remains liable for all payment transactions and any other obligations the Merchant may have incurred. Upon termination, ZamuPay has the right to prohibit the Merchant&rsquo;s access to the Platform, including without limitation by deactivating usernames and passwords, and to refusing future access to the payment gateway by the Merchant.</p>
        <p class="terms-header">INTELLECTUAL PROPERTY</p>
        <p>The sole and exclusive title, right and interest in and to all intellectual property rights, including but not limited to copyright, patent, utility model, data base, trade secret, proprietary information, whether registered or not, relating to the Payment Gateway and any materials provided by ZamuPay and all copies, modifications, alterations and derivative works thereof by whomever produced are and shall remain solely with ZamuPay and its suppliers.</p>
        <p>In no event shall the Merchant gain any rights of title or any intellectual property rights in or to the Payment Gateway or any materials provided by ZamuPay.</p>
        <p class="terms-header">Warranties and Limitation of Liability&nbsp;</p>
        <p>ZamuPay will report any suspicious account activity to its Money Laundering Reporting Officer (MLRO). The MLRO may escalate the suspicious activity to the relevant law enforcement authority.&nbsp;</p>
        <p>ZamuPay shall use all reasonable efforts to ensure that all Transaction requests are processed in a timely manner. However, Zamu makes no representations or warranties as to continuous, uninterrupted or secure access to the payment gateway, which may be affected by factors outside Zamu&rsquo;s control, or may be subject to periodic testing, repair, maintenance or upgrades.&nbsp;</p>
        <p>ZamuPay will not be responsible for any claim unless caused by willful default attributable to Zamu.&nbsp;</p>
        <p>The Merchant shall be liable for damage (loss) caused by a breach of the Payment Gateway Terms and Conditions regardless of fault.</p>
        <p class="terms-header">OTHER PROVISIONS</p>
        <p>The Provider has a right to unilaterally change the Payment Gateway Terms and Conditions and The Merchant accepts the Provider&rsquo;s entitlement to do so.</p>
        <p>If the Provider changes the Payment Gateway Terms and conditions, the Provider is obliged to notify the Merchant of this amendment in advance by e-mail containing a link to the new Payment Gateway Terms and Conditions from where the Merchant may print them or download them in electronic form.</p>
        <p class="terms-header">Dispute Resolution.</p>
        <p>Any dispute arising out of or in connection with this Agreement that is not resolved by Zamu Customer Care may be brought to the attention of the Zamu Manager&nbsp;</p>
        <p>Any dispute arising out of or in connection with this Agreement that is not resolved by the Zamu Manager responsible for the operations shall be referred to arbitration by a single arbitrator to be appointed by agreement between the parties or in default of such agreement within 60 days of the notification of a dispute, upon the application of either party, by the Chairman for the time being of the Chartered Institute of Arbitration (Kenya Chapter). Such arbitration shall be conducted in the English language in Nairobi in accordance with the Rules of Arbitration of the said Institute and subject to and in accordance with the provisions of the Arbitration Act 1995.</p>
        <p class="terms-header">&nbsp; &nbsp;Governing Law.</p>
        <p>&nbsp; &nbsp;The Agreement shall be governed by and construed in accordance with the laws of Kenya.</p>
        <p><br></p>
        <p>I / We ________________________________ hereby confirm that we have read the terms of this agreement and agree to be bound by them.</p>
        <table cellpadding="0" cellspacing="0">
            <tbody>
                <tr>
                    <td style="width: 92.2222%;" valign="middle">
                        <p>Signed by: ......................................................................................................</p>
                    </td>
                    <td style="width: 6.6667%;" valign="middle">
                        <p><br></p>
                    </td>
                </tr>
                <tr>
                    <td style="width: 92.2222%;" valign="middle">
                        <p>Name: .............................................................................................................</p>
                    </td>
                    <td style="width: 6.6667%;" valign="middle">
                        <p><br></p>
                    </td>
                </tr>
                <tr>
                    <td style="width: 92.2222%;" valign="middle">
                        <p>Designation: ..................................................................................................</p>
                    </td>
                    <td style="width: 6.6667%;" valign="middle">
                        <p><br></p>
                    </td>
                </tr>
                <tr>
                    <td style="width: 92.2222%;" valign="middle">
                        <p>Signature: ......................................................................................................</p>
                    </td>
                    <td style="width: 6.6667%;" valign="middle">
                        <p><br></p>
                    </td>
                </tr>
                <tr>
                    <td style="width: 92.2222%;" valign="middle">
                        <p>Date: ................................................................................................................</p>
                    </td>
                    <td style="width: 6.6667%;" valign="middle">
                        <p><br></p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p><br></p>
     
        <p><br></p>
              
    </v-flex>
    
  </div>
</template>

<script>
export default {
    name: 'TermsConditions',
    components: {
      Slider: () => import('./slider'),

    },

}
</script>
<style scoped>
.terms-header {
  font-weight: 700;
}
.FAQHolder p, .FAQHolder li {
    font-size: 14px;
}

</style>